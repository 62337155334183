import { Link } from "gatsby"
import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Email from '../images/email-icon.svg'
import LinkedIn from '../images/linkedin-icon.svg'
import Dribbble from '../images/dribbble-icon.svg'

const Footer = () => {

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    social {
                        email
                        email_mailto
                        dribbble
                        linkedin
                    }
                }
            }
            allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
                edges {
                    node {
                        excerpt
                        fields {
                            slug
                        }
                        frontmatter {
                            order
                            title
                            subheader
                            summary
                            backgroundcolor
                            textcolor
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 1120, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const { social } = data.site.siteMetadata;
    const posts = data.allMarkdownRemark.edges;

    return (
        <footer>   
            <div className = "background background-white">
                <div className = "footer-container">
                    <div className = "footer-1">
                        <div className = "footer-header">Eric Lam</div>
                        <div className = "footer-subheader">User Experience Designer</div>
                        <div className = "footer-text ">
                        <br/>Always down for a conversation, say hello!
                        <br/><a href={social.email_mailto} target="_top" className = "intro-link dark-gray-link">{social.email}</a><br/>
                        <a href = {social.email_mailto} target="_blank" rel="noreferrer" className = "footer-icon" style={{marginTop: "8px"}} ><img className = "footer-svg" src={Email} alt="Email"/></a>
                        <a href = {social.linkedin} target="_blank" rel="noreferrer" className = "footer-icon" style={{marginTop: "8px"}}><img className = "footer-svg" src={LinkedIn} alt="LinkedIn"/></a>
                        <a href = {social.dribbble} target="_blank" rel="noreferrer" className = "footer-icon" style={{marginTop: "8px"}}><img className = "footer-svg" src={Dribbble} alt="Dribbble"/></a>
                        </div>
                    </div>
                    <div className = "footer-2">
                        <div className = "footer-text">
                            <br/><Link to= "/" target="_top" className = "intro-link intro-link-bullet dark-gray-link">Work</Link><br/><br/>
                            <div className = "footer-secondary-links">
                                {posts.map(({ node }) => {
                                    const title = node.frontmatter.title || node.fields.slug;
                                    if (node.fields.slug === "/pss/") {
                                        return(
                                            <div/>
                                        )
                                    }
                                    else if (node.fields.slug === "/dlv4/") {
                                        return(
                                            <div/>
                                        )
                                    }
                                    else {
                                        return(
                                            <div>
                                                <Link to= {node.fields.slug} target="_top" rel="noreferrer" className = "secondary-link">{title}</Link>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className = "footer-3 footer-text">
                        <br/><Link to= "/about" target="_top" className = "intro-link intro-link-bullet dark-gray-link">About</Link>
                    </div>
                </div>
                <div className = "footer-container">
                    <div className = "footer-1 footer-text medium-dark-gray-text footer-copyright">
                        &copy; 2020 Eric Lam
                    </div>
                    <div className = "footer-2">
                    </div>
                    <div className = "footer-3">
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;