import React from 'react'
import Logo from '../images/logo.svg'
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Link } from 'gatsby'
import Contact from '../images/contact-icon.svg'
import About from '../images/about-icon.svg'
import Work from '../images/work-icon.svg'
import { useStaticQuery, graphql } from "gatsby"


const Header = () => {

    const componentDidMount = () => { // eslint-disable-line no-unused-vars
        window.addEventListener("scroll", this.resizeHeaderOnScroll);
    }

    const resizeHeaderOnScroll = () => { // eslint-disable-line no-unused-vars
        const distanceY = window.pageYOffset || document.documentElement.scrollTop,
          shrinkOn = 20,
          header = document.getElementById("navbar-container-id");
    
        if (distanceY > shrinkOn) {
          header.classList.add("minimize");
        } else {
          header.classList.remove("minimize");
        }
    }
    
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    social {
                        email_mailto
                    }
                }
            }
        }
    `)

    const { social } = data.site.siteMetadata


        return (
            <header>
                <div className = "navbar-background">
                    <div className = "navbar-container" id = "navbar-container-id">
                        <div className = "navbar">
                            <div className = "logo-margin">
                                <AniLink fade to="/" duration={0.3} className="logo-focus"><img className = "logo-svg" src={Logo} alt="Logo"/></AniLink>
                            </div>
                            <div className = "logo">
                                <AniLink fade to="/" duration={0.3}>Eric Lam</AniLink>
                            </div>
                            <div className = "navigation">
                                <nav>
                                    <Link to="/" activeClassName="navigation-active">Work</Link>
                                    <div className = "navigation-spacing"></div>
                                    <Link to="/about" activeClassName="navigation-active">About</Link>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div className = "bottom-navbar-background">
                    <div className = "bottom-navbar-container">
                        <div className = "bottom-navbar">
                            <div className = "bottom-navbar-div">
                                <Link to="/" className = "medium-dark-gray-filter" activeClassName="navigation-active dark-gray-filter" id="workLink">
                                    <img className = "navbar-svg" id="workIcon" src={Work}  alt="Work"/>
                                    <br/>Work
                                </Link>
                            </div>
                            <div className = "bottom-navbar-div">
                                <Link to="/about" className = "medium-dark-gray-filter" activeClassName="navigation-active dark-gray-filter">
                                    <img className = "navbar-svg" id="aboutIcon" src={About} alt="About"/>
                                    <br/>About
                                </Link>
                            </div>
                            <div className = "bottom-navbar-div">
                                <a href = {social.email_mailto} className = "medium-dark-gray-filter">
                                    <img className = "navbar-svg" src={Contact} alt="Contact" target="_top" id="contact-icon"/>
                                    <br/>Contact
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
}

export default Header;